import * as React from 'react';
import { IMenuItemProps } from './menu-itemProps';

/**
 *
 * PlayPauseTrigger React component for video player
 *
 */
export default class MenuItem extends React.PureComponent<IMenuItemProps> {
    private menuItem: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    constructor(props: IMenuItemProps) {
        super(props);
        this._onClick = this._onClick.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onBlur = this._onBlur.bind(this);
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public render(): JSX.Element {
        const { ...props } = this.props;
        let className = this.props.className;
        const hasChildren = props.subMenu ? true : false;
        if (hasChildren) {
            className += ' menu__parent menu__position__right';
        } else if (this.props.selected) {
            className += ' menu__selected menu__position__right';
        } else if (this.props.isBackButton) {
            className +=' menu__back menu__position__left';
        }
        return (
            <li id={props.id} role='presentation'>
                <button
                    ref={this.menuItem}
                    className={className}
                    data-has-children={hasChildren}
                    {...(props.data ? {'data-info':props.data} : {})}
                    role={props.selectable ? `menuitemradio` : `menuitem`}
                    aria-setsize={props.itemCount}
                    aria-posinset={props.itemIndex! + 1}
                    data-itemindex={props.itemIndex!}
                    data-isbackbutton={props.isBackButton}
                    {...(props.selectable && props.selected ? {'aria-selected':true, 'aria-checked':true} : {})}
                    {...(props.selectable ? {'data-video-selectable':true} : {})}
                    {...(hasChildren ? {'aria-expanded':false, 'aria-haspopup':true} : {})}
                    {...(props.persistOnClick ? {'data-persist':true} : {})}
                    {...(props.ariaLabel ? {'aria-label':props.ariaLabel} : {})}
                    {...(props.language ? {lang:props.language} : {})}
                    {...(props.hasMouseHoverEvents ? {'data-has-events': props.hasMouseHoverEvents}: {})}
                    onClick={this._onClick}
                    onFocus={this._onFocus}
                    onMouseOver={this._onMouseOver}
                    onMouseOut={this._onMouseOut}
                    onKeyDown={this._onKeyDown}
                    onKeyUp={this._onKeyUp}
                    onBlur={this._onBlur}
                >
                        {props.image  && <img src={props.image} alt={props.imageAlt || ''} className='menu__image'/>}
                        {props.label}
                </button>
            </li>
        );
    }

    public componentDidMount(): void {
        if(this.props.hasFocus) {
            setTimeout(() => {
                this.menuItem.current!.focus();
            },
                       0);
        }
    }

    public componentDidUpdate(): void {
        if(this.props.hasFocus) {
            setTimeout(() => {
                this.menuItem.current!.focus();
            },
                       0);
        }
    }

    private _onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.props.onClick!(event as unknown as Event);
    }

    private _onFocus(event: React.FocusEvent<HTMLButtonElement>): void {
        this.props.onFocus!(event as unknown as Event);
    }

    private _onMouseOver(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.props.onMouseOver!(event as unknown as Event);
    }

    private _onMouseOut(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.props.onMouseOut!(event as unknown as Event);
    }

    private _onKeyDown(event: React.KeyboardEvent<HTMLButtonElement>): void {
        event.stopPropagation();
        this.props.onKeyDown!(event as unknown as Event);
    }

    private _onKeyUp(event: React.KeyboardEvent<HTMLButtonElement>): void {
        event.stopPropagation();
        this.props.onKeyUp!(event as unknown as Event);
    }

    private _onBlur(event: React.FocusEvent<HTMLButtonElement>): void {
        this.props.onBlur!(event as unknown as Event);
    }
}
