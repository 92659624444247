import { IActionContext } from '@msdyn365-commerce/core';
import { readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export default async function refreshCart(cartId: string, ctx: IActionContext): Promise<Cart | undefined> {
    return readAsync({ callerContext: ctx, bypassCache: 'get' }, cartId)
        .then(cart => {
            return cart;
        }).catch(error => {
            return undefined;
        });
}