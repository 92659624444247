import { IActionContext } from '@msdyn365-commerce/core';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function updateCart(cart: Readonly<Cart | undefined>, updatedCartObject: Cart, actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED' };
    }

    delete updatedCartObject.Id;

    return updateAsync(
        { callerContext: actionContext },
        { Id: cart.Id, ...updatedCartObject }
    )
        .then(newCart => {
            return <ICartActionResultWithCart>{ cart: newCart, status: 'SUCCESS' };
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to update the cart');

            return <ICartActionResultWithCart>{ cart: undefined, status: 'FAILED' };
        });
}