import { IActionContext } from '@msdyn365-commerce/core';

export declare type GlobalStateStatus = 'READY' | 'UPDATING' | 'ERROR';

export type GlobalStateConstructor<T extends IGlobalState> = new (actionContext: IActionContext) => T;

export function createGlobalState<T extends IGlobalState>(ctor: GlobalStateConstructor<T>, actionContext: IActionContext): T {
    return new ctor(actionContext);
}

export interface IGlobalState {
    readonly status: GlobalStateStatus;

    initialize(): Promise<void>;
}