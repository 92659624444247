// tslint:disable-next-line:no-any
export function format(formatSpecifier: string, ...parameters: any[]): string {
    if (!formatSpecifier) {
        return '';
    }

    return formatSpecifier.replace(/{(\d+)}/g, (match: string, index: number) => {

        if (index >= parameters.length) {
            return match;
        }

        const value = parameters[index];

        if (typeof value !== 'number' && !value) {
            return '';
        }

        if (typeof value === 'string') {
            return value;
        }

        return value.toString();
    });
}

/*
* @name - isNullOrWhiteSpace
* @description - Determines if the specified string is undefined, null, empty, or whitespace.
* @export
* @public
* @param {string} value - The string to examine.
* @returns {boolean} - True if the value is undefined, null, empty, or whitespace, otherwise false.
*/
export function isNullOrWhiteSpace(value: string): boolean {
    return (!value) || (typeof value !== 'string') || (!trim(value));
}

/*
* @name - trim
* @description - Trims leading and trailing whitespace from the string.
* @export
* @public
* @param {string} value - The string to examine.
* @returns {string} - The trimmed string.
*/
export function trim(value: string): string {
    // Any flavor of !value (ie. null/undefined/'') should return itself.
    if (!value || (typeof value !== 'string')) {
        return value;
    }

    if (value.trim) {
        return value.trim();
    }

    return value.replace(/^\s+|\s+$/g, '');
}

/**
 * @name - startsWith
 * @description - Determines if the specified string starts with the specified value.
 * @export
 * @public
 * @param {string} value - The string to look in.
 * @param {string} prefix - The prefix to look for.
 * @returns {boolean} - True if the string starts with the prefix, otherwise false.
 */
export function startsWith(value: string, prefix: string, ignoreCase: boolean = true): boolean {
    if (!value || !prefix) {
        return false;
    }

    if (ignoreCase) {
        value = value.toLocaleLowerCase();
        prefix = prefix.toLocaleLowerCase();
    }

    if ((value).startsWith) {
        return (value).startsWith(prefix);
    }

    return value.indexOf(prefix) === 0;
}