import { IVideoMetadata } from '../../components/data/player-metadata';
import { closedCaptionsPresetMap, closedCaptionsSettingsMap, closedCaptionsSettingsOptions, videoClosedCaptionsConstant } from '../closed-captions/video-closed-captions-settings';
import { ICurrentClosedCaptionsSettings, IPlayerMenu, IPlayerMenuItem, MenuCategories } from '../data/player-constants';
import { ClosedCaptionTypes } from '../data/player-metadata';
import { format } from '../utilities/stringExtensions';
import LocalizationHelper, { ccCultureLocStrings, PlayerLocKeys } from './localization-helper';
import PlayerHelper from './playerHelper';

/**
 *
 * Closed Caption helper class
 *
 */
export default class ClosedCaptionHelper {

    private playerMetaData: IVideoMetadata;
    private playerHelper: PlayerHelper;
    private localizationHelper: LocalizationHelper;

    constructor(props: IVideoMetadata, playerHelper: PlayerHelper, localizationHelper: LocalizationHelper) {
        this.playerMetaData = props;
        this.playerHelper = playerHelper;
        this.localizationHelper = localizationHelper;
    }

    public checkClosedCaptionsFiles(): boolean {
        if(this.playerMetaData.ccFiles && this.playerMetaData.ccFiles.length > 0) {
            return true;
        }
        return false;
    }

    public getClosedCaptionMenu(): IPlayerMenu | null {
        if(this.playerMetaData.ccFiles && this.playerMetaData.ccFiles.length > 0) {
            const ccFiles = this.playerMetaData.ccFiles;

            const ccMenuItems: IPlayerMenuItem[] = [];

            for (const ccFile of ccFiles) {
                // if ccType is not provided we default to TTML
                if (!ccFile.ccType || ccFile.ccType === ClosedCaptionTypes.TTML) {

                    const lang = this.localizationHelper.getLanguageCodeFromLocale(ccFile.locale.toLowerCase());

                    const ccMenuItem: IPlayerMenuItem = {
                        id: this.playerHelper.addIdPrefix(ccFile.locale),
                        label: ccCultureLocStrings[ccFile.locale],
                        language: lang,
                        data: ccFile.locale,
                        selected: false,
                        selectable: true,
                        persistOnClick: true,
                        ariaLabel: `${ccCultureLocStrings[ccFile.locale]} ${this.localizationHelper.getLocalizedValue(PlayerLocKeys.closecaption)}`
                    };
                    ccMenuItems.push(ccMenuItem);
                }
            }

            ccMenuItems.push({
                id: this.playerHelper.addIdPrefix('appearance'),
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_appearance),
                selected: false,
                selectable: false,
                subMenu: this._getClosedCaptionsSettingsMenu()
            });

            // add 'off' option to the top of the list
            ccMenuItems.unshift({
                id: this.playerHelper.addIdPrefix('off'),
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.closecaption_off),
                selected: false,
                data: 'off',
                selectable: true,
                persistOnClick: true,
                ariaLabel: `${this.localizationHelper.getLocalizedValue(PlayerLocKeys.closecaption_off)} ${this.localizationHelper.getLocalizedValue(PlayerLocKeys.closecaption)}`
            });

            return {
                category: MenuCategories.ClosedCaption,
                id: this.playerHelper.addIdPrefix(MenuCategories.ClosedCaption),
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.closecaption),
                items: ccMenuItems,
            };

        } else {
            return null;
        }
    }

    public getUpdatedClosedCaptionSetting(currentClosedCaptionsSetting: ICurrentClosedCaptionsSettings, settingKey:string, optionKey:string): ICurrentClosedCaptionsSettings {
        if (settingKey === videoClosedCaptionsConstant.presetKey) {
            const presetValue = closedCaptionsPresetMap[optionKey];

            return {
                window_color: currentClosedCaptionsSetting.window_color,
                window_opacity: currentClosedCaptionsSetting.window_opacity,
                text_background_color:  presetValue.text_background_color,
                text_background_opacity: presetValue.text_background_opacity,
                text_color: presetValue.text_color,
                text_edge_style: presetValue.text_edge_style,
                text_font: presetValue.text_font,
                text_opacity: presetValue.text_opacity,
                text_size: presetValue.text_size,
                preset: optionKey
            };
        } else {
            if (this._getOptionValue(settingKey, optionKey)) {
                currentClosedCaptionsSetting[videoClosedCaptionsConstant.presetKey] = 'none';
                currentClosedCaptionsSetting[settingKey] = optionKey;
                return currentClosedCaptionsSetting;
            } else {
                return currentClosedCaptionsSetting;
            }
        }
    }

    /**
     * @name - getClosedCaptionURLFromLocale
     * @description - Gets the value of closed captions URL from the locale.
     * @private
     * @param {string} locale - locale.
     * @returns {string} - Returns the closed caption URL
     */
    public getClosedCaptionURLFromLocale(locale: string): string {
        let url: string = '';
        const ccFiles = this.playerMetaData.ccFiles!;
        for (const ccFile of ccFiles) {
            if(ccFile.locale === locale) {
                url = ccFile.url;
            }
        }
        return url;
    }

    /**
     * @name - getOptionValue
     * @description - Gets the value of closed captions settings option.
     * @private
     * @param {string} settingKey - The closed ceptions settings key.
     * @param {string} optionKey - The closed captions option key.
     * @returns {void}
     */
    private _getOptionValue(settingKey: string, optionKey: string): string {
        const setting = closedCaptionsSettingsMap[settingKey];
        if (setting) {
            const option = closedCaptionsSettingsOptions[setting.option];
            return option && option.map[optionKey];
        }
        return '';
    }
    /**
     * @name - getClosedCaptionsSettingsMenu
     * @description - Gets the closed captions settings menu to be added to options menu.
     * @private
     * @returns {IPlayerMenu}
     */
    // tslint:disable-next-line: max-func-body-length
    private _getClosedCaptionsSettingsMenu(): IPlayerMenu {

        // const currentSettings = currentClosedCaptionsSetting;

        // create customize settings menu
        const settingsMenuItems: IPlayerMenuItem[] = [];

        for (const settingKey of Object.keys(closedCaptionsSettingsMap)) {
            const setting = closedCaptionsSettingsMap[settingKey];

            const option = closedCaptionsSettingsOptions[setting.option];
            const optionMenuItem: IPlayerMenuItem[] = [];

            // tslint:disable-next-line: forin no-for-in
            for (const optionKey in option.map) {
                optionMenuItem.push({
                    id: this._getCCMenuItemId(settingKey, optionKey),
                    label: this.localizationHelper.getLocalizedValue(`${option.pre}${optionKey}`),
                    selectable: true,
                    selected: false,
                    persistOnClick: true,
                    data: `${settingKey}:${optionKey}`
                });
            }

            // Add back button
            optionMenuItem.unshift({
                id: '',
                label: this.localizationHelper.getLocalizedValue(`cc_${settingKey}`),
                ariaLabel: `${settingKey} - go back to previous menu`,
                selectable: false,
                selected: false,
                persistOnClick: false,
                isBackButton: true
            });

            settingsMenuItems.push({
                id: this.playerHelper.addIdPrefix(`${settingKey}_item`),
                label: this.localizationHelper.getLocalizedValue(`cc_${settingKey}`),
                selectable: false,
                subMenu: {
                    id: this._getCCSettingsMenuId(settingKey),
                    category: MenuCategories.ClosedCaptionSettings,
                    items: optionMenuItem,
                    label: this.localizationHelper.getLocalizedValue(`cc_${settingKey}`)
                }
            });

        }

        settingsMenuItems.unshift({
            id: '',
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_customize),
            ariaLabel: 'Customize - go back to previous menu',
            selectable: false,
            selected: false,
            persistOnClick: false,
            isBackButton: true
        });

        const settingsMenu: IPlayerMenu = {
            id: this.playerHelper.addIdPrefix(MenuCategories.ClosedCaptionSettings),
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_customize),
            category: MenuCategories.ClosedCaptionSettings,
            items: settingsMenuItems
        };

        // create preset items menu
        const presetItems: IPlayerMenuItem[] = [];

        for (const preset of Object.keys(closedCaptionsPresetMap)) {
            // tslint:disable-next-line: no-any
            const presetSetting: any = closedCaptionsPresetMap[preset];
            const font: string = presetSetting.text_font;
            const color: string = presetSetting.text_color;

            const ariaLabelValue = format(this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_presettings), '',
                                          this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_text_font),
                                          this.localizationHelper.getLocalizedValue(`cc_font_name_${font}`),
                                          this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_text_color),
                                          this.localizationHelper.getLocalizedValue(`cc_color_${color}`));

            presetItems.push({
                id: this._getCCMenuItemId(videoClosedCaptionsConstant.presetKey, preset),
                label: this.localizationHelper.getLocalizedValue(`cc_${preset}`),
                data: `preset:${preset}`,
                selectable: true,
                selected: false,
                persistOnClick: true,
                ariaLabel: ariaLabelValue,
                hasMouseHoverEvents: true
            });
        }

        presetItems.push({
            id: this.playerHelper.addIdPrefix('cc-customize'),
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_customize),
            subMenu: settingsMenu,
            hasMouseHoverEvents: true
        });

        presetItems.push({
            id: 'reset',
            label:this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_reset),
            data: 'reset',
            persistOnClick: true,
            hasMouseHoverEvents: true
        });

        presetItems.unshift({
            id: '',
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_appearance),
            ariaLabel: 'Appreance - go back to previous menu',
            selectable: false,
            selected: false,
            persistOnClick: false,
            isBackButton: true,
            hasMouseHoverEvents: true
        });

        return {
            id: this._getCCSettingsMenuId(videoClosedCaptionsConstant.presetKey),
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.cc_appearance),
            category: MenuCategories.ClosedCaptionSettings,
            items: presetItems
        };
    }

    /**
     * @name - getCCMenuItemId
     * @description - Gets the close captions setting menu item id.
     * @private
     * @param {string} settingKey - The setting key.
     * @param {string} optionsKey - The option key.
     * @returns {string}
     */
    private _getCCMenuItemId(settingId: string, optionId: string): string {
        return this.playerHelper.addIdPrefix(`cc-${settingId}-${optionId}`);
    }

    /**
     * @name - getCCSettingsMenuId
     * @description - Gets the close captions setting menu id.
     * @private
     * @param {string} settingKey - The setting key.
     * @returns {string}
     */
    private _getCCSettingsMenuId(settingId: string): string {
    return this.playerHelper.addIdPrefix(`cc-${settingId}`);
}

}