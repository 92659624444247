export interface IClientDimension {
    width: number;
    height: number;
}

/* A type safe Dictionary<string, string> */
export interface IDictionaryStringString {
    [key: string]: string;
}

/*
* @interface IGrid
* @classdesc - The IGrid interface contract.
* @export
*/
export interface IGrid {
    rows: number;
    columns: number;
}

/**
 * Clamp position between a range
 * @param  {number} - Value to be clamped
 * @param  {number} - Minimum value in range
 * @param  {number} - Maximum value in range
 * @returns the clap value if min passed is less than max else returns 0
 */
export function clamp(value: number, min:number, max:number): number {
    if (min <= max) {
        return Math.min(Math.max(value, min), max);
    } else {
        return 0;
    }
}

/**
 * upper case the first letter of the string, if only one char exists on the string, uppercase it
 * @param  {string} - Value to be upper cased
 * @returns the upper cased string of the passed value, if it has just one char uppercase it
 */
export function toUpperCaseFirstChar(value: string): string {
    if(value.length > 1) {
        return value.charAt(0).toUpperCase() + value.substr(1);
    }
    if(value.length === 1) {
        return value.charAt(0).toUpperCase();
    } else {
        return '';
    }
}

export function htmlCollectionToArray(nodeList: HTMLCollection): HTMLElement[] {
    return Array.prototype.slice.call(nodeList);
}

export function getCSS(element: HTMLElement, property: string): string {
    let style = element.style[property];

    if (!style) {
        style = getComputedStyle(element)[property];
    }

    return style;
}

export function createThrottledEvent(listener: EventListener, threshold: number):
(event: Event) => void {
    // tslint:disable-next-line:no-any
    let timer: any;
    let last = 0;

    return  (event: Event) => {
        const now = Date.now();

        if (timer) {
            clearTimeout(timer);
            timer = undefined;
        }

        if (!!last && (now < last + threshold)) {
            timer = setTimeout(() => {
                last = Date.now();
                listener(event);
            },
                               threshold - (now - last));
        } else {
            last = now;
            listener(event);
        }
    };
}

export function addThrottledEvent(target: EventTarget, name: string, listener: EventListener, threshold: number = 150):
 (event: Event) => void {

    const throttledEventHandler = createThrottledEvent(listener, threshold);
    target.addEventListener(name, throttledEventHandler, false);

    return throttledEventHandler;
}

/* RTS utilities */
/* TODO: Using any here for now, need to convert cssmodules into a dictionary in all modules */
/* tslint:disable:no-any */
export function mapToCssModules(className: string = '', cssModule: any): string {
    if (!cssModule) {
        return className;
    }

    return className
        .split(' ')
        .map((c: string) => cssModule[c] || c)
        .join(' ');
}

export function isRef(target: any): boolean {
    if (!target) {
        return false;
    }

    return (<React.RefObject<HTMLElement>>target).current !== undefined;
}

export function isElement(target: any): boolean {
    if (!target) {
        return false;
    }

    return (<HTMLElement>target).addEventListener !== undefined;
}
/* tslint:enable:no-any */

export const keyCodes = {
    esc: 27,
    space: 32,
    tab: 9,
    up: 38,
    down: 40,
    enter: 13
};

interface IMap<T> {
    [key: string]: T;
}
const warned: IMap<boolean> = {};

/* Disable typeof check: https://github.com/Microsoft/tslint-microsoft-contrib/issues/415 */
/* tslint:disable:no-typeof-undefined */
export function warnOnce(message: string): void {
    if (!warned[message]) {
        if (typeof console !== 'undefined') {
            throw new Error(message);
        }
        warned[message] = true;
    }
}

export const canUseDOM = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
);
/* tslint:enable:no-typeof-undefined */

export function findDOMElements(target: string): NodeListOf<Element> | string | null {
    try {
        if (typeof target === 'string' && canUseDOM) {
            let selection = document.querySelectorAll(target);

            if (!selection.length) {
              selection = document.querySelectorAll(`#${target}`);
            }

            if (!selection.length) {
              throw new Error();
            }
            return selection;
        }

        return target;
    } catch {
        console.error(`The target '${target}' could not be identified in the dom, tip: check spelling`);
        return null;
    }
  }

export function getTarget(target: string | HTMLElement | React.Ref<HTMLElement>): HTMLElement | React.Ref<HTMLElement> | null | string {
    const selection = findDOMElements(<string>target);
    if (selection && selection.length) {
        return <HTMLElement>selection[0];
    }

    return target;
}

export function getTargetElement(target: string | HTMLElement | React.Ref<HTMLElement>): HTMLElement | null {
    const targetElement = getTarget(target);
    if (isRef(targetElement)) {
        return (<React.RefObject<HTMLElement>>targetElement).current;
    }
    if (isElement(targetElement)) {
        return <HTMLElement>targetElement;
    }

    return null;
}

export const TransitionTimeouts = {
    Fade: 150,
    Collapse: 350,
    Modal: 300,
    Carousel: 600
};

export type TransitionStatuses = 'entering' | 'entered' | 'exiting' | 'exited';

export const focusableElements = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'iframe',
  'object',
  'embed',
  '[tabindex]:not(.modal)',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
];

/*
* @name - extend
* @description - extends an object with properties/values from one or more others.
* @export
* @public
* @param  {any[]} parameters - The objects to merge together.
* @returns {any} - The extended object.
*/

// tslint:disable-next-line: cyclomatic-complexity tslint:disable-next-line: no-any
export function extend(...parameters: any[]): any {
    if (!parameters || !parameters.length) {
        return null;
    }

    const recursive = (typeof (parameters[0]) === 'boolean') && parameters[0];

    if (parameters.length < 2) {
        return recursive ? null : parameters[0];
    }

    if (recursive && (parameters.length < 3)) {
        return parameters[1];
    }

    const target = recursive ? parameters[1] : parameters[0];

    for (let paramIndex = recursive ? 2 : 1; paramIndex < parameters.length; paramIndex++) {
        if(parameters[paramIndex] !== undefined) {
            for (const key of Object.keys(parameters[paramIndex])) {
                if (parameters[paramIndex].hasOwnProperty(key)) {
                    const source = parameters[paramIndex][key];

                    if (recursive) {
                        let isSourceArray = Array.isArray
                            ? Array.isArray(source)
                            : ({}).toString.call(source) === '[object Array]';
                        let isTargetArray = !!target[key] && (Array.isArray
                            ? Array.isArray(target[key])
                            : ({}).toString.call(target[key]) === '[object Array]');
                        let isSourceObject = !isSourceArray && (typeof source === 'object');
                        let isTargetObject = !isTargetArray && !!target[key] && (typeof target[key] === 'object');

                        if (isSourceArray && isTargetArray) {
                            for (let arrayIndex = 0; arrayIndex < source.length; arrayIndex++) {
                                isSourceArray = Array.isArray
                                    ? Array.isArray(source[arrayIndex])
                                    : ({}).toString.call(source[arrayIndex]) === '[object Array]';
                                isTargetArray = !!target[key][arrayIndex] && (Array.isArray
                                    ? Array.isArray(target[key][arrayIndex])
                                    : ({}).toString.call(target[key][arrayIndex]) === '[object Array]');
                                isSourceObject = !isSourceArray && (typeof source[arrayIndex] === 'object');
                                isTargetObject = !isTargetArray && !!target[key][arrayIndex] && (typeof target[key][arrayIndex] === 'object');

                                if (isSourceArray) {
                                    target[key][arrayIndex] = extend(true, isTargetArray ? target[key][arrayIndex] : [], source[arrayIndex]);
                                } else if (isSourceObject) {
                                    target[key][arrayIndex] = extend(true, isTargetObject ? target[key][arrayIndex] : {}, source[arrayIndex]);
                                } else {
                                    target[key][arrayIndex] = source[arrayIndex];
                                }
                            }
                            continue;
                        } else if (isSourceArray) {
                            target[key] = extend(true, [], source);
                            continue;
                        } else if (isSourceObject) {
                            target[key] = extend(true, isTargetObject ? target[key] : {}, source);
                            continue;
                        }
                    }

                    target[key] = source;
                }
            }
        }
    }

    return target;
}

/*
* @name - getDimensions
* @description - Gets the dimension of an element.
* @export
* @param  {HTMLElement} containerElement - The container element.
* @returns {IClientDimension} - The client dimension.
*          {IClientDimension.width} - The client width
*          {IClientDimension.height} - The client height
*/
export function getDimensions(containerElement: HTMLElement): IClientDimension | null {
    if (containerElement === null) {
        return null;
    }
    return {
        width: containerElement.clientWidth,
        height: containerElement.clientHeight
    };
}
