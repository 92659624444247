import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext, IObservableAction } from '@msdyn365-commerce/core';
import { createGlobalState, GlobalStateConstructor, IGlobalState } from '../global-state/i-global-state';

export declare type GlobalStateType = 'CARTSTATE' | 'CHECKOUTSTATE';

/**
 * Shared class that handles global state inputs
 */
export class GlobalStateInput<TOut extends IGlobalState> implements IActionInput {
    public readonly stateConstructor: GlobalStateConstructor<TOut>;
    public readonly key: GlobalStateType;
    private apiSettings: ICommerceApiSettings;

    constructor(key: GlobalStateType, stateConstructor: GlobalStateConstructor<TOut>, apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
        this.stateConstructor = stateConstructor;
        this.key = key;
    }

    public getCacheKey = () => `${this.key}-chanId:${this.apiSettings.channelId}-catId:${this.apiSettings.catalogId}`;
    public getCacheObjectType = () => this.key;
    public dataCacheType = (): CacheType => 'instance';
}

export async function getState<TOut extends IGlobalState>(input: GlobalStateInput<TOut>, ctx: IActionContext): Promise<TOut> {
    const globalStateObject = createGlobalState(input.stateConstructor, ctx);

    await globalStateObject.initialize();

    return globalStateObject;
}

export default function createGlobalStateDataAction<TOut extends IGlobalState>(key: GlobalStateType, stateConstructor: GlobalStateConstructor<TOut>): IObservableAction<TOut> {
    return createObservableDataAction({
        action: <IAction<TOut>>getState,
        input: (args: ICreateActionContext) => {
            return new GlobalStateInput(key, stateConstructor, args.requestContext.apiSettings);
        }
    });
}