import { IActionContext } from '@msdyn365-commerce/core';
import { computed, observable } from 'mobx';

import { GlobalStateStatus, IGlobalState } from './i-global-state';

/**
 * Base global state that handles common global state behavior that other state can extend from
 */
export class GlobalState implements IGlobalState {
    protected isInitialized: boolean = false;
    protected actionContext: IActionContext;

    @observable protected _status: GlobalStateStatus;
    @computed public get status(): Readonly<GlobalStateStatus> {
        return this._status;
    }

    constructor(actionContext: IActionContext) {
        this.actionContext = actionContext;
        this._status = 'UPDATING';
    }

    public async initialize(): Promise<void> {
        this.isInitialized = true;
        this._status = 'READY';

        return;
    }

    protected async _doAsyncAction<TOut>(lambda: () => Promise<TOut>): Promise<TOut> {
        this._status = 'UPDATING';

        return lambda().then(result => {
            this._status = 'READY';
            return result;
        }).catch(error => {
            console.log(error);
            this._status = 'ERROR';

            return Promise.reject(error);
        });
    }
}
