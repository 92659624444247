import { BaseCartState } from '../cart-state/base-cart-state';
import getCheckoutCart, { GetCheckoutCartInput } from '../data-actions/get-checkout-cart';

/**
 * Version of BaseCartState that uses the checkout cart
 */
export class BaseCheckoutCartState extends BaseCartState {
    public async initialize(): Promise<void> {
        if (this.isInitialized) {
            return;
        }

        const newCart = await getCheckoutCart(new GetCheckoutCartInput(this.actionContext.requestContext.apiSettings), this.actionContext);

        if (newCart) {
            this._cart = newCart;
            this._status = 'READY';
        } else {
            this._status = 'ERROR';
        }

        this.isInitialized = true;
    }
}