import { IActionContext } from '@msdyn365-commerce/core';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function clearCartLineDeliveryMode(cart: Readonly<Cart | undefined>, cartLineId: string, actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart || !cart.CartLines) {
        return { cart: undefined, status: 'FAILED'};
    }

    const matchingLines = cart.CartLines.filter(cartLine => cartLine.LineId === cartLineId);

    if (matchingLines.length === 0) {
        return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
    }

    const copyCartLine: CartLine = {...matchingLines[0]};

    copyCartLine.DeliveryMode = '';
    copyCartLine.FulfillmentStoreId = '';
    copyCartLine.ShippingAddress = {};

    return updateCartLinesAsync({ callerContext: actionContext}, cart.Id, [copyCartLine],null)
        .then(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS'};
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Update Cart Line');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
        });
}