
/**
 * @description - Player constants
 */
export const PlayerConstants = {
    defaultVolume: 0.8,
    defaultVolumeSliderSteps: 5,
    defaultVideoSliderSteps: 1,
    defaultControlPanelTimeout: 6500,
    defaultPositionUpdateThreshold: 0.1,
    defaultMaskLevel: 40,
    defaultPlayGlyphIcon: 'fas fa-play',
    defaultPauseGlyphIcon: 'fas fa-pause',
    defaultMuteGlyphIcon: 'fas fa-volume-up',
    defaultUnMuteGlyphIcon: 'fas fa-volume-off',
    defaultFullScreenGlyphIcon: 'fas fa-expand',
    defaultExitFullScreenGlyphIcon: 'fas fa-compress',
    closedCaptionGlyphClassName: 'fas fa-closed-captioning',
    thumbnailFullWidth: 1110,
    thumbnailFullHeight: 624,
    ccLangPrefKey: 'vidccpref',
    seekBarLabel: 'Seek bar',
    regxForAgeGateInputValidation : /^[0-9\b]+$/,
    mutePrefKey: 'vidmut',
    volumePrefKey: 'vidvol'
};

/**
 * @description - names used for video and player html elements
 */
export const VideoPlayerNames = {
    videoplayerContainer: 'msc-videoplayer',
    mediaType: 'video/mp4'
};

export const MenuCategories = {
    AudioTracks: 'audio-tracks',
    ClosedCaption: 'close-caption',
    ClosedCaptionSettings: 'cc-settings',
    PlaybackSpeed: 'playback-speed',
    Quality: 'quality',
    Share: 'share',
    Download: 'download',
    Options: 'options'
};

export const MediaEvents = ['abort',
    'canplay',
    'canplaythrough',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    'progress',
    'ratechange',
    'readystatechange',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    'timeupdate',
    'volumechange',
    'waiting'];

export interface IPlayPosition {
    /**
     * @name startTime
     * @type {number}
     * @description Start position of the buffer, in seconds.
     *              For VoD this is usually 0.
     *              For live stream this is a position of beginning of DVR window, relative to the stream start time.
     */
    startTime: number;

    /**
     * @name endTime
     * @type {number}
     * @description End position of the buffer, in seconds.
     *              For VoD this is normally a video duration.
     *              For live stream this is normally a "live" position, relative to the stream start time.
     */
    endTime: number;

    /**
     * @name currentTime
     * @type {number}
     * @description Position currently on the screen, relative to the stream start time, in seconds.
     */
currentTime: number;
    /**
     * @name bufferedDuration
     * @type {number}
     * @description buffered duration of the video.
     */
    bufferedDuration: number;
}

export interface ICurrentClosedCaptionsSettings {
    preset: string;
    text_font: string;
    text_color: string;
    text_opacity: string;
    text_size: string;
    text_edge_style: string;
    text_background_color: string;
    text_background_opacity: string;
    window_color: string;
    window_opacity: string;
}

/*
* @name - PlayerEvents
* @description - Player states.
* @type {IPlayerState}
*/
export type PlayerStates = 'init' | 'playerLoaded' | 'loading' |
                        'ready'  | 'playing' | 'paused' | 'buffering' |
                        'seeking' | 'ended' | 'error' | 'stopped';

/*
* @enum VideoErrorCodes
* @description - Enum for supported media quality.
*/
export enum VideoErrorCodes {
    // Buffering.
    BufferingFirstByteTimeout = 2000,

    // Media element errors.
    MediaErrorAborted = 2100,
    MediaErrorNetwork = 2101,
    MediaErrorDecode = 2102,
    MediaErrorSourceNotSupported = 2103,
    MediaErrorUnknown = 2104,

    // Media selection.
    MediaSelectionNoMedia = 2200,
}

/*
* @interface IVideoErrorData
* @description - The data contract interface used for storing references to video error data.
*/
export interface IVideoErrorData {
    errorCode: VideoErrorCodes;
    message?: string;
}

/*
* @interface IVideoErrorMessageData
* @description - The data contract interface used for supplying content to an error message.
* @export
*/
export interface IVideoErrorMessageData {
    title?: string;
    message?: string;
}

/**
 * @interface - IPlayerMenuItem
 * @interfacedesc - The IPlayerMenuItem interface that defines the data structure used to store menu item information.
 * @export
 */
export interface IPlayerMenuItem {
    id: string;
    label: string;
    className?: string;
    language?: string;
    data?: string;
    image?: string;
    itemCount?: number;
    itemIndex?: number;
    imageAlt?: string;
    glyph?: string;
    selected?: boolean;
    selectable?: boolean;
    hasMouseHoverEvents?: boolean;
    persistOnClick?: boolean;
    isBackButton?: boolean;
    ariaLabel?: string;
    subMenu?: IPlayerMenu;
    hasFocus?: boolean;
}

/**
 * @interface - IPlayerMenu
 * @interfacedesc - The IPlayerMenu interface that defines the data structure used to store menu information.
 * @export
 */
export interface IPlayerMenu {
    id: string;
    category: string;
    items: IPlayerMenuItem[];
    label?: string;
    glyph?: string;
    cssClass?: string;
    hideBackButton?: boolean;
}

export interface IPlayerMenuItemClickNotification {
    category: string;
    id: string;
    data?: string;
}
