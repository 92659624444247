import { mapToCssModules, TransitionStatuses, TransitionTimeouts } from '@msdyn365-commerce-modules/utilities';
import classNames from 'classnames';
import * as React from 'react';
import Transition from 'react-transition-group/Transition';
import CarouselContext from './CarouselContext';
import { ICarouselItemProps } from './CarouselItem.props';

export interface ICarouselItemState {
    startAnimation: boolean;
}

/**
 * CarouselItem component
 */
export default class CarouselItem extends React.Component<ICarouselItemProps, ICarouselItemState> {

    public static defaultProps: Partial<ICarouselItemProps> = {
        tag: 'div',
        timeout: TransitionTimeouts.Carousel,
        slide: true,
    };
    public props: ICarouselItemProps;
    public state: ICarouselItemState;

    constructor(props: ICarouselItemProps) {
        super(props);
        this.props = props;

        this.state = {
            startAnimation: false,
        };

        this.onEnter = this.onEnter.bind(this);
        this.onEntering = this.onEntering.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    public onEnter = (node: HTMLElement, isAppearing: boolean) =>  {
        this.setState({ startAnimation: false });
        if (this.props.onEnter) {
            this.props.onEnter(node, isAppearing);
        }
    }

    public onEntering = (node: HTMLElement, isAppearing: boolean) => {

        // getting this variable triggers a reflow
        const offsetHeight = node.offsetHeight;
        this.setState({ startAnimation: true });
        if (this.props.onEntering) {
            this.props.onEntering(node, isAppearing);
        }

        return offsetHeight;
    }

    public onExit = (node: HTMLElement) => {
        this.setState({ startAnimation: false });
        if (this.props.onExit) {
            this.props.onExit(node);
        }
    }

    public onExiting =  (node: HTMLElement) => {
        this.setState({ startAnimation: true });
        node.dispatchEvent(new CustomEvent('slide.bs.carousel'));
        if (this.props.onExiting) {
            this.props.onExiting(node);
        }
    }

    public onExited = (node: HTMLElement) => {
        node.dispatchEvent(new CustomEvent('slid.bs.carousel'));
        if (this.props.onExited) {
            this.props.onExited(node);
        }
    }

    public render(): JSX.Element | null {

        const {
            children,
            cssModule, slide,
            tag,
            className,

            in : collapseIn,
            mountOnEnter, unmountOnExit,
            appear, enter, exit, timeout,
            onEnter, onEntering, onEntered, onExit, onExiting, onExited,

            ...otherProps
        } = this.props;

        const timeoutOrDefault = timeout || TransitionTimeouts.Carousel;
        const Tag = tag || 'div';
        const transitionProps = {
            in : collapseIn,
            mountOnEnter, unmountOnExit,
            appear, enter, exit,
            timeout: timeoutOrDefault
        };

        return(
            <CarouselContext.Consumer >
                {(
                    // tslint:disable-next-line:typedef
                    { direction}
                ) => (
                    <Transition
                        {...transitionProps}
                        enter={slide}
                        exit={slide}
                        in={collapseIn}
                        onEnter={this.onEnter}
                        onEntering={this.onEntering}
                        onExit={this.onExit}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                    >
                        {(status: TransitionStatuses) => {
                            const isActive = (status === 'entered') || (status === 'exiting');
                            const directionClassName = (status === 'entering' || status === 'exiting') &&
                                this.state.startAnimation &&
                                (direction === 'right' ? ('msc-carousel__item__left') : ('msc-carousel__item__right'));
                            const orderClassName = (status === 'entering') &&
                                (direction === 'right' ? ('msc-carousel__item__next') : ('msc-carousel__item__prev'));
                            const itemClasses = mapToCssModules(
                                classNames(
                                    className,
                                    'msc-carousel__item',
                                    isActive && 'active',
                                    directionClassName,
                                    orderClassName,
                                ),
                                cssModule
                            );

                            return (
                                <Tag className={itemClasses} {...otherProps}>
                                    {children}
                                </Tag>
                            );
                        }}
                    </Transition>
                )}
            </CarouselContext.Consumer>
        );
    }

}
