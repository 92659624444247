import get from 'lodash/get';
import { action, computed, observable } from 'mobx';

import { IActionContext } from '@msdyn365-commerce/core';
import {
    Address, AttributeValueBase, CommerceProperty, GiftCard, SalesOrder, SimpleProduct, TenderLine, TokenizedPaymentCard
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { GlobalState } from '../global-state/global-state';
import { ICartState } from '../state-interfaces/i-cart-state';
import { ICheckoutActionResult, ICheckoutGenericData, ICheckoutState, IGiftCardExtend } from '../state-interfaces/i-checkout-state';
import { BaseCheckoutCartState } from './base-checkout-cart-state';
import { CheckoutStorageKey } from './checkout-state-storage';

/**
 * State information related to what is needed to succesfully perform a checkout
 */
export class BaseCheckoutState extends GlobalState implements ICheckoutState {
    @observable protected _checkoutCart: ICartState;
    @computed public get checkoutCart(): ICartState {
        return this._checkoutCart;
    }

    @observable protected _salesOrder?: SalesOrder;
    @computed public get salesOrder(): Readonly<SalesOrder | undefined> {
        return this._salesOrder;
    }

    @observable protected _attributeValues: AttributeValueBase[];
    @computed public get attributeValues(): Readonly<AttributeValueBase[] | undefined> {
        return this._attributeValues;
    }

    @observable protected _extensionProperties: CommerceProperty[];
    @computed public get extensionProperties(): Readonly<CommerceProperty[] | undefined> {
        return this._extensionProperties;
    }

    @observable protected _orderedProducts?: SimpleProduct[];
    @computed public get orderedProducts(): Readonly<SimpleProduct[] | undefined> {
        return this._orderedProducts;
    }

    @observable protected _tokenizedPaymentCard?: TokenizedPaymentCard;
    @computed public get tokenizedPaymentCard(): Readonly<TokenizedPaymentCard | undefined> {
        return this._tokenizedPaymentCard;
    }

    @observable protected _tenderLine?: TenderLine;
    @computed public get tenderLine(): Readonly<TenderLine | undefined> {
        return this._tenderLine;
    }

    @observable protected _billingAddress?: Address;
    @computed public get billingAddress(): Readonly<Address | undefined> {
        return this._billingAddress;
    }

    @observable protected _isBillingAddressSameAsShippingAddress?: boolean;
    @computed public get isBillingAddressSameAsShippingAddress(): Readonly<boolean | undefined> {
        return this._isBillingAddressSameAsShippingAddress;
    }

    @observable protected _shippingAddress?: Address;
    @computed public get shippingAddress(): Readonly<Address | undefined> {
        return this._shippingAddress;
    }

    @observable protected _cardPrefix?: string;
    @computed public get cardPrefix(): Readonly<string | undefined> {
        return this._cardPrefix;
    }

    /**
     * @deprecated Use giftCardExtends method
     */
    @computed public get giftCards(): Readonly<GiftCard[]> {
        return this.giftCardExtends;
    }

    @observable protected _giftCardExtends: IGiftCardExtend[] = [];
    @computed public get giftCardExtends(): Readonly<IGiftCardExtend[]> {
        return this._giftCardExtends;
    }

    @observable protected _loyaltyCardNumber?: string;
    @computed public get loyaltyCardNumber(): Readonly<string | undefined> {
        return this._loyaltyCardNumber;
    }

    @observable protected _loyaltyAmount: number = 0;
    @computed public get loyaltyAmount(): Readonly<number> {
        return this._loyaltyAmount;
    }

    @observable protected _guestCheckoutEmail: string = '';
    @computed public get guestCheckoutEmail(): Readonly<string> {
        return this._guestCheckoutEmail;
    }

    @observable protected _checkoutGenericData: object = {};

    constructor(actionContext: IActionContext) {
        super(actionContext);
        this._checkoutCart = new BaseCheckoutCartState(actionContext);
        this._attributeValues = [];
        this._extensionProperties = [];
    }

    public async initialize(): Promise<void> {
        if (this.isInitialized) {
            return;
        }

        await this._checkoutCart.initialize();

        this.setInitialData();
        this.isInitialized = true;
        this._status = 'READY';
        this._attributeValues = [];
        this._extensionProperties = [];
    }

    @action
    public updateSalesOrder(input: { newSalesOrder: SalesOrder; newOrderedProducts: SimpleProduct[]; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._salesOrder = input.newSalesOrder;
        this._orderedProducts = input.newOrderedProducts;
        this._attributeValues = input.newSalesOrder.AttributeValues || [];
        this._extensionProperties = input.newSalesOrder.ExtensionProperties || [];

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public async updateAttributeValues(input: { newAttributeValues: AttributeValueBase[]; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._attributeValues = input.newAttributeValues;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public async updateExtensionProperties(input: { newExtensionProperties: CommerceProperty[]; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._extensionProperties = input.newExtensionProperties;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateTokenizedPaymentCard(input: { newTokenizedPaymentCard: TokenizedPaymentCard; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._tokenizedPaymentCard = input.newTokenizedPaymentCard;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateTenderLine(input: { newTenderLine?: TenderLine; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._tenderLine = input.newTenderLine;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public async updateShippingAddress(input: { newShippingAddress: Address; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._shippingAddress = input.newShippingAddress;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateBillingAddress(input: { newBillingAddress: Address; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._billingAddress = input.newBillingAddress;

        // @ts-ignore
        this._isBillingAddressSameAsShippingAddress = input.additionalProperties?.isBillingAddressSameAsShippingAddress || false;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public removeBillingAddress(input: { additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._billingAddress = undefined;
        this._isBillingAddressSameAsShippingAddress = undefined;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateCardPrefix(input: { newCardPrefix: string; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._cardPrefix = input.newCardPrefix;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public removeGiftCard(input: { giftCardNumber: string; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._giftCardExtends = this._giftCardExtends.filter(giftCard => giftCard.Id !== input.giftCardNumber);

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public removeGiftCardExtend(input: { giftCardNumber: string; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._giftCardExtends = this._giftCardExtends.filter(giftCard => giftCard.Id !== input.giftCardNumber);

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public addGiftCard(input: { giftCard: GiftCard; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        // @ts-ignore
        const { Pin, ExpirationDate, TenderTypeId } = input.additionalProperties || {}

        const gitCardExtend = { ...input.giftCard, ...{ Pin, ExpirationDate, TenderTypeId } };

        this._giftCardExtends = [gitCardExtend, ...this._giftCardExtends];

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public addGiftCardExtend(input: { giftCard: IGiftCardExtend; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._giftCardExtends = [input.giftCard, ...this._giftCardExtends];

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateLoyaltyCardNumber(input: { newLoyaltyCardNumber: string; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._loyaltyCardNumber = input.newLoyaltyCardNumber;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateLoyaltyAmount(input: { newAmount: number; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._loyaltyAmount = Number(input.newAmount.toFixed(2));

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public async updateGuestCheckoutEmail(input: { newGuestCheckoutEmail: string; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._guestCheckoutEmail = input.newGuestCheckoutEmail;
        const emailOnCart = this.checkoutCart.cart.ReceiptEmail;

        if (emailOnCart !== input.newGuestCheckoutEmail) {
            await this.checkoutCart.updateReceiptEmail({ newEmail: input.newGuestCheckoutEmail });
        }

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public removeCheckoutCartId(input: { additionalProperties?: object }): Promise<ICheckoutActionResult> {
        //@ts-ignore
        this.actionContext.requestContext.cookies.removeCheckoutCartCookie();
        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public saveDataInStorage(input: { additionalProperties?: object }): Promise<ICheckoutActionResult> {
        const { sessionStorage } = this.actionContext.requestContext;

        // Add all local data to session storage
        if (this.billingAddress) {
            sessionStorage.setCheckoutCartData(CheckoutStorageKey.BillingAddress, { billingAddress: this.billingAddress, isBillingAddressSameAsShippingAddress: this.isBillingAddressSameAsShippingAddress });
        }

        if (this.giftCardExtends && this.giftCardExtends.length > 0) {
            sessionStorage.setCheckoutCartData(CheckoutStorageKey.GiftCard, this.giftCardExtends);
        }

        if (this.loyaltyCardNumber) {
            sessionStorage.setCheckoutCartData(CheckoutStorageKey.LoyaltyCardNumber, { loyaltyCardNumber: this.loyaltyCardNumber });
        }

        if (this.loyaltyAmount) {
            sessionStorage.setCheckoutCartData(CheckoutStorageKey.LoyaltyAmount, { loyaltyAmount: this.loyaltyAmount });
        }

        // save generic data with SaveDataBeforeRedirect value true
        const filtered = Object.keys(this._checkoutGenericData).reduce((filteredData: object, key: string) => {
            if (this._checkoutGenericData[key].SaveDataBeforeRedirect) {
                filteredData[key] = this._checkoutGenericData[key];
            }
            return filteredData;
        }, {});

        if (Object.keys(filtered).length > 0) {
            sessionStorage.setCheckoutCartData(CheckoutStorageKey.CheckoutGenericData, { checkoutGenericData: filtered });
        }

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public setCheckoutGenericData(input: { key: string; checkoutGenericData: ICheckoutGenericData; additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this._checkoutGenericData[input.key] = input.checkoutGenericData;
        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public getCheckoutGenericData(input: { key: string }): ICheckoutGenericData | undefined {
        return this._checkoutGenericData[input.key];
    }

    @action
    private cleanCheckoutData(input: { additionalProperties?: object }): Promise<ICheckoutActionResult> {
        this.actionContext.requestContext.sessionStorage.removeCheckoutCartData();
        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    private isPaymentVerificationRedirection(): boolean {
        const { requestFormData, query } = this.actionContext.requestContext;
        return (requestFormData && query && query.pv === '1') ? true : false;
    }

    private setInitialData() {
        const { sessionStorage } = this.actionContext.requestContext;

        if (this.isPaymentVerificationRedirection()) {
            const billingAddress = sessionStorage.getCheckoutCartData(CheckoutStorageKey.BillingAddress);
            const giftCard = sessionStorage.getCheckoutCartData(CheckoutStorageKey.GiftCard);

            this._billingAddress = get(billingAddress, 'billingAddress');
            this._isBillingAddressSameAsShippingAddress = get(billingAddress, 'isBillingAddressSameAsShippingAddress');
            this._giftCardExtends = (giftCard && Array.isArray(giftCard) ? giftCard : []) as IGiftCardExtend[];
            this._loyaltyCardNumber = get(sessionStorage.getCheckoutCartData(CheckoutStorageKey.LoyaltyCardNumber), 'loyaltyCardNumber');
            this._loyaltyAmount = get(sessionStorage.getCheckoutCartData(CheckoutStorageKey.LoyaltyAmount), 'loyaltyAmount');
            this._checkoutGenericData = get(sessionStorage.getCheckoutCartData(CheckoutStorageKey.CheckoutGenericData), 'checkoutGenericData') || {};
        }

        this.cleanCheckoutData({});
    }
}