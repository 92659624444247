import { IActionContext } from '@msdyn365-commerce/core';
import { removeCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function removeCartLinesInternal(cart: Readonly<Cart | undefined>, cartLineIds: string[], actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED' };
    }

    return removeCartLinesAsync({ callerContext: actionContext}, cart.Id, cartLineIds)
        .then(newCart => {
            // This is because of a TSProxy typing error
            const returnedCart = Array.isArray(newCart) ? newCart[0] : newCart;

            return <ICartActionResultWithCart> { cart: returnedCart, status: 'SUCCESS' };
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Remove Cart Line');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
        });
}