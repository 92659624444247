import * as React from 'react';

type CarouselContext = {
    direction?: string;
};

// Create a context wrapping some shared state
export default React.createContext<CarouselContext>({
    direction: 'right'
});
