import { IPlayerResources } from '../../components/data/player-resources';
import { IDictionaryStringString } from '../utilities/utilities';

    /**
     * @name - ccCultureLocStrings
     * @description - The localized names for the close caption menu options.
     * @const
     * @export
     * @type {IDictionaryStringString}
     */
export const ccCultureLocStrings: IDictionaryStringString = {'ar-ab':'عربي', 'ar-xm':'عربي', 'ar-ma':'عربي', 'ar-sa':'عربي',
    'eu-es':'Euskara', 'bg-bg':'Български', 'ca-es':'Català', 'zh-cn':'简体中文', 'zh-hk':'繁體中文', 'zh-tw':'繁體中文', 'hr-hr':'Hrvatski',
    'cs-cz':'Čeština', 'da-dk':'Dansk', 'nl-be':'Nederlands', 'nl-nl':'Nederlands', 'en-ab':'English', 'en-aa':'English', 'en-au':'English',
    'en-ca':'English', 'en-eu':'English', 'en-hk':'English', 'en-in':'English', 'en-id':'English', 'en-ie':'English', 'en-jm':'English',
    'en-my':'English', 'en-nz':'English', 'en-pk':'English', 'en-ph':'English', 'en-sg':'English', 'en-za':'English', 'en-tt':'English',
    'en-gb':'English', 'en-us':'English', 'et-ee':'Eesti', 'fi-fi':'Suomi',  'fr-ab':'Français', 'fr-be':'Français', 'fr-ca':'Français',
    'fr-fr':'Français', 'fr-xf':'Français', 'fr-ch':'Français', 'gl-es':'Galego', 'de-de':'Deutsch', 'de-at':'Deutsch', 'de-ch':'Deutsch',
    'el-gr':'Ελληνικά', 'he-il':'עברית', 'hi-in':'हिंदी', 'hu-hu':'Magyar', 'is-is':'Íslenska', 'id-id':'Bahasa Indonesia', 'it-it':'Italiano',
    'ja-jp':'日本語', 'kk-kz':'Қазақ', 'ko-kr':'한국어', 'lv-lv':'Latviešu', 'lt-lt':'Lietuvių', 'ms-my':'Bahasa Melayu (Asia Tenggara)‎',
    'nb-no':'Norsk (bokmål)', 'nn-no':'Norsk (nynorsk)', 'fa-ir':'فارسی', 'pl-pl':'Polski', 'pt-br':'Português (Brasil)‎',
    'pt-pt':'Português (Portugal)‎', 'ro-ro':'Română', 'ru-ru':'Русский', 'sr-latn-rs':'Srpski', 'sk-sk':'Slovenčina', 'sl-si':'Slovenski',
    'es-ar':'Español', 'es-cl':'Español', 'es-co':'Español', 'es-cr':'Español', 'es-do':'Español', 'es-ec':'Español', 'es-us':'Español',
    'es-gt':'Español', 'es-hn':'Español', 'es-xl':'Español', 'es-mx':'Español', 'es-ni':'Español', 'es-pa':'Español', 'es-py':'Español',
    'es-pe':'Español', 'es-pr':'Español', 'es-es':'Español', 'es-uy':'Español', 'es-ve':'Español', 'sv-se':'Svenska', 'tl-ph':'Tagalog',
    'th-th':'ไทย', 'tr-tr':'Türkçe', 'uk-ua':'Українська', 'ur-pk':'اردو', 'vi-vn':'Tiếng Việt'};

    /**
     * @name - ccLanguageCodes
     * @description - The 2 character language codes for use with the lang attribute .
     * @const
     * @export
     * @type {IDictionaryStringString}
     */
export const ccLanguageCodes: IDictionaryStringString = {'ar-ab':'ar', 'ar-xm':'ar', 'ar-ma':'ar',
    'ar-sa':'ar', 'eu-es':'eu', 'bg-bg':'bg', 'ca-es':'ca', 'zh-cn':'zh', 'zh-hk':'zh', 'zh-tw':'zh',
    'hr-hr':'hr', 'cs-cz':'cs', 'da-dk':'da', 'nl-be':'nl', 'nl-nl':'nl', 'en-ab':'en', 'en-aa':'en',
    'en-au':'en', 'en-ca':'en', 'en-eu':'en', 'en-hk':'en', 'en-in':'en', 'en-id':'en', 'en-ie':'en',
    'en-jm':'en', 'en-my':'en', 'en-nz':'en', 'en-pk':'en', 'en-ph':'en', 'en-sg':'en', 'en-za':'en',
    'en-tt':'en', 'en-gb':'en', 'en-us':'en', 'et-ee':'et', 'fi-fi':'fi',  'fr-ab':'fr', 'fr-be':'fr',
    'fr-ca':'fr', 'fr-fr':'fr', 'fr-xf':'fr', 'fr-ch':'fr', 'gl-es':'gl', 'de-de':'de', 'de-at':'de',
    'de-ch':'de', 'el-gr':'el', 'he-il':'he', 'hi-in':'hi', 'hu-hu':'hu', 'is-is':'is', 'id-id':'id',
    'it-it':'it', 'ja-jp':'ja', 'kk-kz':'kk', 'ko-kr':'ko', 'lv-lv':'lv', 'lt-lt':'lt', 'ms-my':'ms‎',
    'nb-no':'nb', 'nn-no':'nn', 'fa-ir':'fa', 'pl-pl':'pl', 'pt-br':'pt‎', 'pt-pt':'pt‎', 'ro-ro':'ro',
    'ru-ru':'ru', 'sr-latn-rs':'sr', 'sk-sk':'sk', 'sl-si':'sl', 'es-ar':'es', 'es-cl':'es', 'es-co':'es',
    'es-cr':'es', 'es-do':'es', 'es-ec':'es', 'es-us':'es', 'es-gt':'es', 'es-hn':'es', 'es-xl':'es',
    'es-mx':'es', 'es-ni':'es', 'es-pa':'es', 'es-py':'es', 'es-pe':'es', 'es-pr':'es', 'es-es':'es',
    'es-uy':'es', 'es-ve':'es', 'sv-se':'sv', 'tl-ph':'tl', 'th-th':'th', 'tr-tr':'tr', 'uk-ua':'uk',
    'ur-pk':'ur', 'vi-vn':'vi'};

/**
 * @name - playerLocKeys
 * @description - Key names to get localized values.
 * @const
 * @export
 */
export const PlayerLocKeys = {
    audio_tracks: 'audio_tracks',
    closecaption_off: 'closecaption_off',
    audio_tracks_default: 'audio_tracks_default',
    geolocation_error: 'geolocation_error',
    media_err_aborted: 'media_err_aborted',
    media_err_decode: 'media_err_decode',
    media_err_network: 'media_err_network',
    media_err_src_not_supported: 'media_err_src_not_supported',
    media_err_unknown_error: 'media_err_unknown_error',
    media_err_amp_encrypt: 'media_err_amp_encrypt',
    media_err_amp_player_mismatch: 'media_err_amp_player_mismatch',
    browserunsupported: 'browserunsupported',
    browserunsupported_download: 'browserunsupported_download',
    expand: 'expand',
    mute: 'mute',
    nullvideoerror: 'nullvideoerror',
    pause: 'pause',
    play: 'play',
    play_video: 'play_video',
    playing: 'playing',
    paused: 'paused',
    play_pause_button_tooltip: 'play_pause_button_tooltip',
    live_caption: 'live_caption',
    live_label: 'live_label',
    playbackerror: 'playbackerror',
    standarderror: 'standarderror',
    time: 'time',
    more_caption: 'more_caption',
    data_error: 'data_error',
    seek: 'seek',
    unexpand: 'unexpand',
    unmute: 'unmute',
    volume: 'volume',
    quality: 'quality',
    quality_hd: 'quality_hd',
    quality_hq: 'quality_hq',
    quality_lo: 'quality_lo',
    quality_sd: 'quality_sd',
    quality_auto: 'quality_auto',
    cc_customize: 'cc_customize',
    cc_text_font: 'cc_text_font',
    cc_text_color: 'cc_text_color',
    cc_color_black: 'cc_color_black',
    cc_color_blue: 'cc_color_blue',
    cc_color_cyan: 'cc_color_cyan',
    cc_color_green: 'cc_color_green',
    cc_color_grey: 'cc_color_grey',
    cc_color_magenta: 'cc_color_magenta',
    cc_color_red: 'cc_color_red',
    cc_color_white: 'cc_color_white',
    cc_color_yellow: 'cc_color_yellow',
    cc_font_name_casual: 'cc_font_name_casual',
    cc_font_name_cursive: 'cc_font_name_cursive',
    cc_font_name_monospacedsansserif: 'cc_font_name_monospacedsansserif',
    cc_font_name_proportionalsansserif: 'cc_font_name_proportionalsansserif',
    cc_font_name_monospacedserif: 'cc_font_name_monospacedserif',
    cc_font_name_proportionalserif: 'cc_font_name_proportionalserif',
    cc_font_name_smallcapitals: 'cc_font_name_smallcapitals',
    cc_text_size: 'cc_text_size',
    cc_text_size_default: 'cc_text_size_default',
    cc_text_size_extralarge: 'cc_text_size_extralarge',
    cc_text_size_large: 'cc_text_size_large',
    cc_text_size_maximum: 'cc_text_size_maximum',
    cc_text_size_small: 'cc_text_size_small',
    cc_appearance: 'cc_appearance',
    cc_preset1: 'cc_preset1',
    cc_preset2: 'cc_preset2',
    cc_preset3: 'cc_preset3',
    cc_preset4: 'cc_preset4',
    cc_preset5: 'cc_preset5',
    cc_presettings: 'cc_presettings',
    cc_text_background_color: 'cc_text_background_color',
    cc_text_background_opacity: 'cc_text_background_opacity',
    cc_text_opacity: 'cc_text_opacity',
    cc_percent_0: 'cc_percent_0',
    cc_percent_100: 'cc_percent_100',
    cc_percent_25: 'cc_percent_25',
    cc_percent_50: 'cc_percent_50',
    cc_percent_75: 'cc_percent_75',
    cc_text_edge_color: 'cc_text_edge_color',
    cc_text_edge_style: 'cc_text_edge_style',
    cc_text_edge_style_depressed: 'cc_text_edge_style_depressed',
    cc_text_edge_style_dropshadow: 'cc_text_edge_style_dropshadow',
    cc_text_edge_style_none: 'cc_text_edge_style_none',
    cc_text_edge_style_raised: 'cc_text_edge_style_raised',
    cc_text_edge_style_uniform: 'cc_text_edge_style_uniform',
    cc_window_color: 'cc_window_color',
    cc_window_opacity: 'cc_window_opacity',
    cc_reset: 'cc_reset',
    closecaption: 'closecaption',
    close_text: 'close_text',
    playbackspeed: 'playbackspeed',
    playbackspeed_normal: 'playbackspeed_normal',
    sharing_label: 'sharing_label',
    sharing_facebook: 'sharing_facebook',
    sharing_twitter: 'sharing_twitter',
    sharing_linkedin: 'sharing_linkedin',
    sharing_skype: 'sharing_skype',
    sharing_mail: 'sharing_mail',
    sharing_copy: 'sharing_copy',
    loading_value_text: 'loading_value_text',
    loading_aria_label: 'loading_aria_label',
    descriptive_audio: 'descriptive_audio',
    unknown_language: 'unknown_language',
    download_label: 'download_label',
    download_transcript: 'download_transcript',
    download_audio: 'download_audio',
    download_video: 'download_video',
    download_videoWithCC: 'download_videoWithCC',
    agegate_day: 'agegate_day',
    agegate_month: 'agegate_month',
    agegate_year: 'agegate_year',
    agegate_enterdate: 'agegate_enterdate',
    agegate_enterdate_arialabel: 'agegate_enterdate_arialabel',
    agegate_fail: 'agegate_fail',
    agegate_verifyyourage: 'agegate_verifyyourage',
    agegate_submit: 'agegate_submit',
    agegate_dateorder: 'agegate_dateorder',
    previous_menu_aria_label: 'previous_menu_aria_label',
    reactive_menu_aria_label: 'reactive_menu_aria_label',
    reactive_menu_aria_label_closedcaption: 'reactive_menu_aria_label_closedcaption',
    interactivity_show: 'interactivity_show',
    interactivity_hide: 'interactivity_hide'
};

export const LocalizationConstants = {
    playVideoAriaLabel: 'play video',
    playAriaLabel: 'play',
    pauseAriaLabel: 'pause',
    closeAriaLabel: 'close',
    optionAriaLabel:'More options',
    muteAriaLabel:'mute',
    unMuteAriaLabel:'unmute',
    fullScreenAriaLabel:'full screen',
    exitFullScreenAriaLabel:'exit full screen',
    playingAriaLabel: 'paying',
    pausedAriaLabel: 'paused',
    seekBarLabel: 'Seek bar',
    videoTimeDurationLabel:'Video time duration',
    closedCaptionLabel: 'Closed caption',
    audio_tracks: 'Audio Tracks',
    descriptive_audio: 'Audio descriptions',
    closecaption_off: 'Off',
    audio_tracks_default: 'Default Track',
    cc_customize: 'Customize',
    cc_text_font: 'Font',
    cc_text_color: 'Text color',
    cc_color_black: 'Black',
    cc_color_blue: 'Blue',
    cc_color_cyan: 'Cyan',
    cc_color_green: 'Green',
    cc_color_grey: 'Grey',
    cc_color_magenta: 'Magenta',
    cc_color_red: 'Red',
    cc_color_white: 'White',
    cc_color_yellow: 'Yellow',
    cc_font_name_casual: 'Casual',
    cc_font_name_cursive: 'Cursive',
    cc_font_name_monospacedsansserif: 'Monospaced Sans Serif',
    cc_font_name_monospacedserif: 'Monospaced Serif',
    cc_font_name_proportionalserif: 'Proportional Serif',
    cc_font_name_proportionalsansserif: 'Proportional Sans Serif',
    cc_font_name_smallcapitals: 'Small Capitals',
    cc_text_size: 'Text size',
    cc_text_size_default: 'Default',
    cc_text_size_extralarge: 'Extra Large',
    cc_text_size_large: 'Large',
    cc_text_size_maximum: 'Maximum',
    cc_text_size_small: 'Small',
    cc_appearance: 'Appearance',
    cc_preset1: 'Preset 1 ',
    cc_preset2: 'Preset 2',
    cc_preset3: 'Preset 3',
    cc_preset4: 'Preset 4',
    cc_preset5: 'Preset 5',
    cc_presettings: 'Close captions appearance {0}: ({1}:{2}, {3}:{4})',
    cc_text_background_color: 'Background color',
    cc_text_background_opacity: 'Background opacity',
    cc_text_opacity: 'Text opacity',
    cc_percent_0: '0%',
    cc_percent_100: '100%',
    cc_percent_25: '25%',
    cc_percent_50: '50%',
    cc_percent_75: '75%',
    cc_text_edge_color: 'Text edge color',
    cc_text_edge_style: 'Text edge style',
    cc_text_edge_style_depressed: 'Depressed',
    cc_text_edge_style_dropshadow: 'Drop shadow',
    cc_text_edge_style_none: 'No edge',
    cc_text_edge_style_raised: 'Raised',
    cc_text_edge_style_uniform: 'Uniform',
    cc_window_color: 'Window color',
    cc_window_opacity: 'Window opacity',
    cc_reset: 'Reset',
};

/**
 *
 * Closed Caption helper class
 *
 */
export default class LocalizationHelper {
    private playerResources: IPlayerResources;

    constructor(props: IPlayerResources) {
        this.playerResources = props;
    }

    /**
     * @name getLocalizedValue
     * @description - Gets the localized value for given key.
     * @public
     * @param {string} key - The key to look for.
     * @returns {string}
     */
    public getLocalizedValue(key: string): string {
        return (this.playerResources[key] ||
            LocalizationConstants[key] || '');
    }

    /**
     * @name getLanguageCodeFromLocale
     * @description - Converts from a locale ID to a 2 character language code
     * @public
     * @param locale - locale ID string
     * @returns {string}
     */
    public getLanguageCodeFromLocale(locale: string): string {
        return ccLanguageCodes[locale] || '';
    }

    /**
     * @name getLanguageNameFromLocale
     * @description - Converts from language ID or locale name to a localized language name
     * @public
     * @param {string} locale - language ID string or locale name string
     * @returns {string}
     */
    public getLanguageNameFromLocale(locale: string): string {
        return ccCultureLocStrings[locale] || this.getLocalizedValue(PlayerLocKeys.unknown_language);
    }
}