import { IActionContext } from '@msdyn365-commerce/core';
import { removeDiscountCodesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function removePromoCodes(cart: Readonly<Cart | undefined>, promoCodes: string[], actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED'};
    }

    return removeDiscountCodesAsync({ callerContext: actionContext }, cart.Id, promoCodes)
        .then(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        }).catch(error => {
            actionContext.telemetry.warning(error);

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
        });
}