import { ICurrentClosedCaptionsSettings } from '../data/player-constants';
import { IDictionaryStringString } from '../utilities/utilities';

export const videoClosedCaptionsConstant = {
    presetKey: 'preset'
};

    /**
     * @name - closedCaptionsSettinsDefaults
     * @description - The default value for closed captions settings.
     */
export const closedCaptionsSettingDefaults: ICurrentClosedCaptionsSettings = {
    preset: 'preset1',
    text_font: 'proportionalsansserif',
    text_color: 'white',
    text_opacity: '100',
    text_size: 'default',
    text_edge_style: 'none',
    text_background_color: 'black',
    text_background_opacity: '100',
    window_color: 'black',
    window_opacity: '0'
    };

    /**
     * @name - closedCaptionsSettingsMap
     * @description - The closed captions settings map.
     */
export const closedCaptionsSettingsMap: IClosedCaptionsSettingsMap = {
    text_font: { value: 'font-family:', option: 'font' },
    text_color: { value: 'color:', option: 'color' },
    text_size: { value: 'font-size:', option: 'text_size' },
    text_edge_style: { value: 'text-shadow:', option: 'text_edge_style' },
    text_opacity: { value: 'color:', option: 'percent' },
    text_background_color: { value: 'background:', option: 'color' },
    text_background_opacity: { value: 'background:', option: 'percent' },
    window_color: { value: 'background:', option: 'color' },
    window_opacity: { value: 'background:', option: 'percent' }
};

    /**
     * @interface IClosedCaptionsSettingsMap
     * @description - The data contract interface used for storing closed caption setting map.
     */
export interface IClosedCaptionsSettingsMap {
    [key: string]: IClosedCaptionsSettings;
}

    /**
     * @interface IClosedCaptionsSettings
     * @description - The data contract interface used for storing closed caption setting values.
     */
export interface IClosedCaptionsSettings {
    value: string;
    option: string;
}

export interface IClosedCaptionsPresetMap {
    [key: string]: IDictionaryStringString;
}

/**
 * @name - closedCaptionsPresetMap
 * @description - The closed captions preset settings map.
 */
export const closedCaptionsPresetMap: IClosedCaptionsPresetMap = {
    preset1: {
        text_font: 'proportionalsansserif',
        text_color: 'white',
        text_opacity: '100',
        text_background_color: 'black',
        text_background_opacity: '100'
    },
    preset2: {
        text_font: 'monospacedserif',
        text_color: 'white',
        text_opacity: '100',
        text_background_color: 'black',
        text_background_opacity: '100'
    },
    preset3: {
        text_font: 'proportionalsansserif',
        text_color: 'yellow',
        text_opacity: '100',
        text_background_color: 'black',
        text_background_opacity: '100'
    },
    preset4: {
        text_font: 'proportionalsansserif',
        text_color: 'blue',
        text_opacity: '100',
        text_background_color: 'grey',
        text_background_opacity: '100'
    },
    preset5: {
        text_font: 'casual',
        text_color: 'white',
        text_opacity: '100',
        text_background_color: 'black',
        text_background_opacity: '100'
    }
};

/**
 * @name - closedCaptionsSettingsOptions
 * @description - The closed captions settings options and value map.
 */
export const closedCaptionsSettingsOptions: IClosedCaptionsOptionsMap = {
    font: {
        pre: 'cc_font_name_', // key prefix for the localization
        map: {
            casual: 'Verdana;font-variant:normal',
            cursive: 'Zapf-Chancery,Segoe script,Cursive;font-variant:normal',
            monospacedsansserif: 'Lucida sans typewriter,Lucida console,Consolas;font-variant:normal',
            monospacedserif: 'Courier;font-variant:normal',
            proportionalsansserif: 'Arial,Sans-serif;font-variant:normal',
            proportionalserif: 'Times New Roman,Serif;font-variant:normal',
            smallcapitals: 'Arial,Helvetica,Sans-serif;font-variant:small-caps'
        }
    },
    percent: {
        pre: 'cc_percent_', // key prefix for the options
        map: {
            0: '0',
            50: '0.5',
            75: '0.75',
            100: '1'
        }
    },
    text_size: {
        pre: 'cc_text_size_', // key prefix for the options
        map: {
            small: '75%',
            default: '100%',
            large: '125%',
            extralarge: '150%',
            maximum: '200%'
        }
    },
    color: {
        pre: 'cc_color_', // key prefix for the options
        map: {
            white: '#FFFFFF',
            black: '#000000',
            blue: '#0000FF',
            cyan: '#00FFFF',
            green: '#00FF00',
            grey: '#BCBCBC',
            magenta: '#FF00FF',
            red: '#FF0000',
            yellow: '#FFFF00'
        }
    },
    text_edge_style: {
        pre: 'cc_text_edge_style_', // key prefix for the options
        map: {
            none: 'none',
            depressed: '1px 1px 0 #FFF,-1px -1px 0 #000',
            dropshadow: '1px 1px 0 #000',
            raised: '1px 1px 0 #000,-1px -1px 0x #FFF',
            uniform: '1px 1px 0 #000,-1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000'
        }
    }
};

/**
 * @interface IClosedCaptionOptionsMap
 * @description - The data contract interface used for storing player closed captions options map.
 */
export interface IClosedCaptionsOptionsMap {
    [key: string]: IClosedCaptionsOptions;
}

/**
 * @interface IClosedCaptionsOptions
 * @description - The data contract interface used for storing closed captions options values.
 */
export interface IClosedCaptionsOptions {
    pre: string;
    map: IDictionaryStringString;
}