import { IActionContext } from '@msdyn365-commerce/core';
import { updateLineDeliverySpecificationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, LineDeliverySpecification } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function updateCartLineDeliverySpecificationsInternal(cart: Readonly<Cart | undefined>, cartLineDeliverySpecifications:  LineDeliverySpecification[], actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart || !cart.CartLines || cartLineDeliverySpecifications.length === 0) {
        return { cart: undefined, status: 'FAILED'};
    }

    return updateLineDeliverySpecificationsAsync(
        { callerContext: actionContext },
        cart.Id,
        cartLineDeliverySpecifications
    )
    .then(newCart => {
        return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS'};
    }).catch(error => {
        actionContext.telemetry.warning(error);
        actionContext.telemetry.debug('Unable to updateLineDeliverySpecificationsAsync');

        return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
    });
}