import { IActionContext } from '@msdyn365-commerce/core';
import { removeDiscountCodesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function removeAllPromoCodes(cart: Readonly<Cart | undefined>, actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED'};
    }

    if (cart.Coupons && cart.Coupons.length > 0) {
        const promoCodes = cart.Coupons.map(coupon => { return coupon.Code || ''; });

        return removeDiscountCodesAsync({ callerContext: actionContext }, cart.Id, promoCodes)
            .then(newCart => {
                return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
            }).catch(error => {
                actionContext.telemetry.warning(error);

                return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
            });
    }

    return <ICartActionResultWithCart> { cart: cart, status: 'SUCCESS' };
}