import {nodeListToArray} from '../utilities/htmlExtensions';
import {format, trim} from '../utilities/stringExtensions';
import {IDictionaryStringString, IGrid} from '../utilities/utilities';
import {ITtmlContext, TtmlContext} from './ttml-context';
import {ITtmlSettings, TtmlSettings, xmlNS} from './ttml-settings';
import {TtmlTimeParser} from './ttml-time-parser';

// Timed Text Markup Language (TTML).
// See http://www.w3.org/TR/ttml1/ for more details.

/*
* @interface ITtmlEvent
* @classdesc - The ITtmlEvent interface contract.
* @export
*/
export interface ITtmlEvent {
    time: number;
    element?: Element;
}

/*
* @interface ITtmlTimingBounds
* @classdesc - The ITtmlTimingBounds interface contract.
* @export
*/
interface ITtmlTimingBounds {
    start: number;
    end: number;
}

/**
 *
 * The TtmlParser class.
 *
 */
// tslint:disable-next-line: no-unnecessary-class
export class TtmlParser {
    /*
    * @name - mediaStart
    * @description - Used as the start time for applyTiming when applying to the entire document.
    * @static
    * @private
    * @type {number}
    */
    private static mediaStart: number  = -1;

    /*
    * @name - mediaEnd
    * @description - Used as the end time for applyTiming when applying to the entire document.
    *                Picking a number that should always be greater than any real media end time.
    * @static
    * @private
    * @type {number}
    */
    private static mediaEnd: number = 99999999;

    /*
    * @name - getLocalTagName
    * @description - Gets the local tagName of a Node.
    * @static
    * @public
    * @param {Node} node - The node to get the tagName of.
    * @returns {string} - The tagName.
    */
   public static getLocalTagName(node: Node): string {
        // tslint:disable-next-line: no-any
        return (<Element>node).localName || (<any>node).baseName;
    }

    /*
    * @name - getAttributeNS
    * @description - Gets the value of a namespaced attribute. First tries element.getAttributeNS() but falls back
    *                to scanning each attribute if that fails.
    *                If you know which cases go into the fallback code please document it here.
    * @static
    * @public
    * @param {Element} element - The element to get the attribute of.
    * @param {string} name - The attribute name.
    * @param {string} namespace - The attribute namespace.
    * @returns {string} - The attribute value. or an empty string if a matching attribute is not found.
    */
   public static getAttributeNS(element: Element, name: string, namespace: string): string {
        let result = element.getAttributeNS(namespace, name);

        if (!result) {
            // Go through all the attributes looking for a match.
            for (const attribute of nodeListToArray(element.attributes)) {
                // tslint:disable-next-line: no-any
                if (((<any>attribute).localName === name) && (attribute.lookupNamespaceURI((<any>attribute).prefix) === namespace)) {
                    // tslint:disable-next-line: no-any
                    result = (<any>attribute).value;
                    break;
                }
            }
        }

        return result!;
    }

    /*
    * @name - parse
    * @description - Parses a ttml document and builds a ttmlContext instance from it.
    * @static
    * @public
    * @param {XMLDocument} ttmlDocument - The ttml document to parse.
    * @param {ITtmlSettings} settingsOverrides - The ttml settings overrides to use.
    * @returns {ITtmlContext} - The resulting ttmlContext instance that can be used to get cues by time.
    */
    public static parse(ttmlDocument: XMLDocument, settingsOverrides: ITtmlSettings): ITtmlContext {
        ttmlDocument = (typeof ttmlDocument === 'string') ? TtmlParser._parseXml(ttmlDocument) : ttmlDocument;

        const ttmlContext = new TtmlContext();

        // Start initializing the context.
        ttmlContext.settings = new TtmlSettings(settingsOverrides);
        ttmlContext.root = TtmlParser._verifyRoot(ttmlDocument, ttmlContext);
        ttmlContext.body = TtmlParser._getFirstElementByTagNameNS(ttmlContext.root, 'body', ttmlContext.settings.ttmlNamespace);
        ttmlContext.events = [];
        ttmlContext.styleSetCache = [];

        if (ttmlContext.body) {
            // Parse the root <tt> attributes.
            TtmlParser._parseTtAttrs(ttmlContext);

            // Set up our regions.
            const head = TtmlParser._ensureRegions(ttmlContext);

            const timeBase = TtmlParser.getAttributeNS(
                ttmlContext.root,
                'timeBase',
                ttmlContext.settings.ttmlParameterNamespace) || 'media';

            if (ttmlContext.settings.supportedTimeBase.indexOf(timeBase) !== -1) {
                // Process textnodes into anonymous spans.
                TtmlParser._processAnonymousSpans(ttmlContext, ttmlContext.body);

                const timeParser = new TtmlTimeParser(ttmlContext.settings.mediaFrameRate, ttmlContext.settings.mediaTickRate);

                // Apply the intervals over the tree.
                TtmlParser._applyTiming(
                    ttmlContext,
                    ttmlContext.root,
                    { start: TtmlParser.mediaStart, end: TtmlParser.mediaEnd },
                    true,
                    timeParser);

                // Apply the style inheritance over the tree.
                TtmlParser._applyStyling(ttmlContext, head);
            }

            // Add an dummy end event for the captions to appear until end of video.
            // Not sure why we need to do this, please add to this comment if you know.
            ttmlContext.events.push({ time: TtmlParser.mediaEnd, element: undefined });

            // Sort events into temporal order.
            ttmlContext.events.sort((event1: ITtmlEvent, event2: ITtmlEvent) => {
                return event1.time - event2.time;
            });
        }

        return ttmlContext;
    }

    /*
    * @name - applyInlineStyles
    * @description - Applies the elements inline styles into the styleSet.
    * @static
    * @public
    * @param {TtmlSettings} settings - The TtmlSettings for this document.
    * @param {IDictionaryStringString} styleSet - The styleSet to build upon.
    * @param {Element} element - The element to apply the styles of.
    * @returns {void}
    */
   public static applyInlineStyles(settings: TtmlSettings, styleSet: IDictionaryStringString, element: Element): void {
        for (const attribute of nodeListToArray(element.attributes)) {
            if (attribute.namespaceURI === settings.ttmlStyleNamespace) {
                // trim() because we see lots of ttml attribute values with trailing space...
                styleSet[TtmlParser.getLocalTagName(attribute)] = trim(attribute.nodeValue!);
            }
        }
    }

    /*
    * @name - parseXml
    * @description - Parses an xml document in string format to an XMLDocument.
    * @static
    * @private
    * @param {string} xmlString - The xml string to parse.
    * @returns {XMLDocument} - The resulting XMLDocument, or null if the xml string failed to parse.
    */
    private static _parseXml(xmlString: string): XMLDocument {
        let xml: XMLDocument;

        // tslint:disable-next-line: no-any
        if ((<any>window).DOMParser) {
            // tslint:disable-next-line: no-any
            const domParser = new (<any>window).DOMParser();
            xml = domParser.parseFromString(xmlString, 'application/xml');
        } else {
            // tslint:disable-next-line: no-any
            const domParser = new (<any>window).ActiveXObject('Microsoft.XMLDOM');
            domParser.async = false;
            domParser.loadXML(xmlString);
            xml = <XMLDocument>domParser;
        }
        return xml;
    }

    /*
    * @name - verifyRoot
    * @description - Verifies that root <tag> of the specified document is the expected ttml <tt> tagName and
    *                updates the settings namespaces from it if they are non standard.
    * @static
    * @private
    * @param {XMLDocument} ttmlDocument - The ttml document to verify.
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @returns {Element} - The valid root Element, or or null if the root Element was not valid.
    */
    private static _verifyRoot(ttmlDocument: XMLDocument, ttmlContext: TtmlContext): Element {
        let root: Element;
        const candidate = ttmlDocument.documentElement;

        if (TtmlParser.getLocalTagName(candidate) === 'tt') {
            // tslint:disable-next-line: no-http-string
            if (candidate.namespaceURI !== 'http://www.w3.org/ns/ttml') {
                ttmlContext.settings.ttmlNamespace = candidate.namespaceURI!;
                ttmlContext.settings.ttmlStyleNamespace = `${ttmlContext.settings.ttmlNamespace}#styling`;
                ttmlContext.settings.ttmlParameterNamespace = `${ttmlContext.settings.ttmlNamespace}#parameter`;
                ttmlContext.settings.ttmlMetaNamespace = `${ttmlContext.settings.ttmlNamespace}#metadata`;
            }

            root = candidate;
        }

        return root!;
    }

    /*
    * @name - parseTtAttrs
    * @description - Parses the <tt> tag attributes and initializes ttmlContext properties from them.
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @returns {void}
    */
    private static _parseTtAttrs(ttmlContext: TtmlContext): void {
        const cellRes = TtmlParser.getAttributeNS(ttmlContext.root, 'cellResolution', ttmlContext.settings.ttmlParameterNamespace);
        const extent = TtmlParser.getAttributeNS(ttmlContext.root, 'extent', ttmlContext.settings.ttmlStyleNamespace);
        let cellGrid: IGrid | null = null;

        if (cellRes) {
            const parts = trim(cellRes).split(/\s+/);

            if (parts.length === 2) {
                const columns = Math.round(parseFloat(parts[0]));
                const rows = Math.round(parseFloat(parts[1]));

                if ((rows > 0) && (columns > 0)) {
                    cellGrid = { rows: rows, columns: columns };
                }
            }
        }

        if (cellGrid) {
            ttmlContext.settings.cellResolution = cellGrid;
        }

        if (extent) {
            if (extent !== 'auto') {
                // Get the individual components.
                const coords = extent.split(/\s+/);

                if ((coords.length === 2) &&
                    (coords[0].substr(coords[0].length - 2) === 'px') &&
                    (coords[1].substr(coords[1].length - 2) === 'px')) {
                    const width = parseFloat(coords[0].substr(0, coords[0].length - 2));
                    const height = parseFloat(coords[1].substr(0, coords[1].length - 2));

                    // Round to integer.
                    ttmlContext.settings.rootContainerRegionDimensions = { width: Math.round(width), height: Math.round(height) };
                }
            }
        }

    }

    /*
    * @name - ensureRegions
    * @description - Ensures we have the elements/regions we require by either finding them or creating them if they are not present.
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @returns {Element} - The <head> element.
    */
    private static _ensureRegions(ttmlContext: TtmlContext): Element {
        // Create our rootContainerRegion
        ttmlContext.rootContainerRegion = <Element>ttmlContext.root.ownerDocument!.createElementNS(
            ttmlContext.settings.ttmlNamespace, 'rootcontainerregion');

        ttmlContext.root.appendChild(ttmlContext.rootContainerRegion);

        const extents = ttmlContext.settings.rootContainerRegionDimensions ? format('{0}px {1}px',
                                                                                    ttmlContext.settings.rootContainerRegionDimensions.width, ttmlContext.settings.rootContainerRegionDimensions.height) : 'auto';

        ttmlContext.rootContainerRegion.setAttributeNS(ttmlContext.settings.ttmlStyleNamespace, 'extent', extents);

        let head = TtmlParser._getFirstElementByTagNameNS(ttmlContext.root, 'head', ttmlContext.settings.ttmlNamespace);

        // Ensure we have a <head>
        if (!head) {
            // No <head> so we need to create that now.
            head = ttmlContext.root.ownerDocument!.createElementNS(ttmlContext.settings.ttmlNamespace, 'head');
            ttmlContext.root.appendChild(head);
        }

        // Ensure we have a <layout>
        ttmlContext.layout = TtmlParser._getFirstElementByTagNameNS(head, 'layout', ttmlContext.settings.ttmlNamespace);

        if (!ttmlContext.layout) {
            // No <layout> so we need to create that now.
            ttmlContext.layout = ttmlContext.root.ownerDocument!.createElementNS(ttmlContext.settings.ttmlNamespace, 'layout');
            ttmlContext.root.appendChild(ttmlContext.layout);
        }

        // Create an anonymous region if we have no regions.
        const regions = ttmlContext.layout.getElementsByTagNameNS(ttmlContext.settings.ttmlNamespace, 'region');

        if (!regions.length) {
            const anonymousRegion = ttmlContext.root.ownerDocument!.createElementNS(ttmlContext.settings.ttmlNamespace, 'region');

            anonymousRegion.setAttributeNS(xmlNS, 'id', 'anonymous');
            anonymousRegion.setAttribute('data-isanonymous', '1');
            ttmlContext.layout.appendChild(anonymousRegion);

            ttmlContext.body.setAttributeNS(ttmlContext.settings.ttmlNamespace, 'region', 'anonymous');
        }

        return head;
    }

    /*
    * @name - processAnonymousSpans
    * @description - Recurses through all the <p> elements in the document and groups all the contiguous TEXT_NODES together into <span>'s
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @param {Element} element - The element to process the TEXT_NODES of.
    * @returns {void}
    */
    private static _processAnonymousSpans(ttmlContext: TtmlContext, element: Element): void {
        // If this element is <p> then group all contiguous textnodes together in <span>
        if (TtmlParser._isTagNS(element, 'p', ttmlContext.settings.ttmlNamespace)) {
            const textNodeGroups: Node[][] = [];
            let prevNodeType: number = 0;

            for (const child of nodeListToArray(element.childNodes)) {
                if (child.nodeType === Node.TEXT_NODE) {
                    if (prevNodeType !== Node.TEXT_NODE) {
                        textNodeGroups.push([]);
                    }

                    textNodeGroups[textNodeGroups.length - 1].push(child);
                }

                prevNodeType = child.nodeType;
            }

            for (const group of textNodeGroups) {
                const anonSpan = ttmlContext.root.ownerDocument!.createElementNS(ttmlContext.settings.ttmlNamespace, 'span');

                anonSpan.appendChild(group[0].parentNode!.replaceChild(anonSpan, group[0]));

                for (let index = 1; index < group.length; index++) {
                    anonSpan.appendChild(group[index]);
                }
            }
        }

        // Then recurse through the contents doing the same thing for those.
        for (const child of nodeListToArray<Element>(element.childNodes)) {
            this._processAnonymousSpans(ttmlContext, child);
        }
    }

    /*
    * @name - applyTiming
    * @description - Recurses through the document element tree to determine the absolute start and end times of all the elements
    *                using the TTML subset of the SMIL timing model. The reference times passed in 'bound' are absolute times.
    *                The result of calling this is to set the local start time and end time to absolute times between these two
    *                reference times, based on the begin, end and dur attributes and to recursively set all of the children.
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @param {Element} element - The element to apply the timing to.
    * @param {ITtmlTimingBounds} bound - The bounds for the timing.
    * @param {boolean} isParallelContext - Indicates whether or not this element is in a parallel timing context.
    * @param {TtmlTimeParser} timeParser - The TtmlTimeParser to use to parse this elements time attributes.
    * @returns {void}
    */
    private static _applyTiming(
        ttmlContext: TtmlContext,
        element: Element,
        bound: ITtmlTimingBounds,
        isParallelContext: boolean,
        timeParser: TtmlTimeParser): void {
        const beginAttribute = TtmlParser.getAttributeNS(element, 'begin', ttmlContext.settings.ttmlNamespace);
        let startTime = beginAttribute ? timeParser.parse(beginAttribute) : bound.start;
        let endTime = 0;

        // Compute the simple duration of the interval.
        let duration = 0;
        let end = 0;
        const durationAttribute = TtmlParser.getAttributeNS(element, 'dur', ttmlContext.settings.ttmlNamespace);
        const endAttribute = TtmlParser.getAttributeNS(element, 'end', ttmlContext.settings.ttmlNamespace);

        if ((!durationAttribute) && (!endAttribute)) {
            // No direct timing attested, so use default based on context.
            // Parallel children have indefinite default duration, truncated by bounds.
            if (isParallelContext) {
                // Sequential children have zero default duration.
                if (startTime <= bound.end) {
                    endTime = bound.end;
                } else {
                    endTime = 0;
                }
            }
        } else if (durationAttribute && endAttribute) {
            // Both duration and end attested, the minimum interval applies.
            duration = timeParser.parse(durationAttribute);
            end = timeParser.parse(endAttribute);
            const minEnd = Math.min(startTime + duration, bound.start + end);
            endTime = Math.min(minEnd, bound.end);
        } else if (endAttribute) {
            // Only end attested.
            end = timeParser.parse(endAttribute);
            endTime = Math.min(bound.start + end, bound.end);
        } else {
            // Only dur attested.
            duration = timeParser.parse(durationAttribute);
            endTime = Math.min(startTime + duration, bound.end);
        }

        if (endTime < startTime) {
            endTime = startTime;
        }

        startTime = Math.floor(startTime);
        endTime = Math.floor(endTime);

        element.setAttribute('data-time-start', startTime.toString());
        element.setAttribute('data-time-end', endTime.toString());

        if ((startTime >= 0) && (ttmlContext.events.filter((event) => { return event.time === startTime; }).length <= 0)) {
            ttmlContext.events.push({ time: startTime, element: element });
        }

        let start = startTime;

        for (const child of nodeListToArray<Element>(element.childNodes)) {
            if (child.nodeType === Node.ELEMENT_NODE) {
                // Parallel is the default so null is OK here.
                if (TtmlParser.getAttributeNS(element, 'timeContainer', ttmlContext.settings.ttmlNamespace) !== 'seq') {
                    this._applyTiming(ttmlContext, child, { start: startTime, end: endTime }, true, timeParser);
                } else {
                    this._applyTiming(ttmlContext, child, { start: start, end: endTime }, false, timeParser);
                    start = parseInt(child.getAttribute('data-time-end')!, 10);
                }
            }
        }
    }

    /*
    * @name - applyStyling
    * @description - Recurses through all the elements in <head> applying the ttml sytling to them based on the <style>'s.
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @param {Element} head - The element to process the TEXT_NODES of.
    * @returns {void}
    */
    private static _applyStyling(ttmlContext: TtmlContext, head: Element): void {
        // First find all the <style>'s
        const styling = TtmlParser._getFirstElementByTagNameNS(head, 'styling', ttmlContext.settings.ttmlNamespace);
        const styles = styling ? nodeListToArray(styling.getElementsByTagNameNS(ttmlContext.settings.ttmlNamespace, 'style')) : [];

        // Apply the styles to every element in the body
        for (const element of nodeListToArray(ttmlContext.root.querySelectorAll('*'))) {
            this._applyStyle(ttmlContext, <Element>element, <Element[]>styles);
        }
    }

    /*
    * @name - applyStyle
    * @description - Applies the ttml styling to the specified element.
    *                Apply styles in the correct order to element by building a styleSet, adding it
    *                to the stlyeSetCache, and the adding reference to the cached styleSet to the element.
    * @static
    * @private
    * @param {TtmlContext} ttmlContext - The ttmlContext for this document.
    * @param {Element} element - The element to apply the ttml styling to.
    * @param {Element[]} styles - The set of styles to apply.
    * @returns {void}
    */
    private static _applyStyle(ttmlContext: TtmlContext, element: Element, styles: Element[]): void {
        const styleSet: IDictionaryStringString = {};

        // Find all the applicable styles and set them as properties on styleSet.
        this._applyStylesheet(ttmlContext.settings, styleSet, element, styles);
        TtmlParser.applyInlineStyles(ttmlContext.settings, styleSet, element);

        let empty = true;

        // tslint:disable-next-line: no-for-in
        for (const style in styleSet) {
            // Just need to see if there's at least one.
            if (styleSet.hasOwnProperty(style)) {
                empty = false;
                break;
            }
        }

        if (!empty) {
            // Record the applied set to the element.
            element.setAttribute('data-styleSet', ttmlContext.styleSetCache.length.toString());
            ttmlContext.styleSetCache.push(styleSet);
        }
    }

    /*
    * @name - applyStylesheet
    * @description - For each style id on the element, find the corresponding style element and then
    *                apply the stylesheet into styleset; this recurses over the tree of referenced styles.
    * @static
    * @private
    * @param {TtmlSettings} settings - The TtmlSettings for this document.
    * @param {IDictionaryStringString} styleSet - The styleSet to build upon.
    * @param {Element} element - The element to apply the styles of.
    * @param {Element[]} styles - The set of styles to apply.
    * @returns {void}
    */
    private static _applyStylesheet(settings: TtmlSettings, styleSet: IDictionaryStringString, element: Element, styles: Element[]): void {
        // Find all the style ID references.
        const styleAttribute = TtmlParser.getAttributeNS(element, 'style', settings.ttmlNamespace);
        const ids = styleAttribute ? styleAttribute.split(/\s+/) : [];

        for (const styleId of ids) {
            for (const style of styles) {
                // Filter on those whose id is the one we want.
                if (TtmlParser.getAttributeNS(style, 'id', xmlNS) === styleId) {
                    // Recurse into its style references.
                    this._applyStylesheet(settings, styleSet, style, styles);

                    // Do inline styles.
                    TtmlParser.applyInlineStyles(settings, styleSet, style);
                }
            }
        }

        // If the element is a region do nested styles. NOTE regions can only be referenced from elements in the body.
        if (TtmlParser._isTagNS(element, 'region', settings.ttmlNamespace)) {
            // Find all the style elements in the TTML namespace.
            for (const style of nodeListToArray(element.getElementsByTagNameNS(settings.ttmlNamespace, 'style'))) {
                TtmlParser.applyInlineStyles(settings, styleSet, <Element>style);
            }
        }
    }

    /*
    * @name - isTagNS
    * @description - Determines whether or not the namespace and local tagName of an element matches
    *                the specified namespace and tag name.
    * @static
    * @private
    * @param {Element} element - The node to compare against.
    * @param {string} tagName - The tag name.
    * @param {string} namespace - The attribute namespace.
    * @returns {boolean} - True if the elements ns/tagName match, otherwise false.
    */
    private static _isTagNS(element: Element, tagName: string, namespace: string): boolean {
        return ((element.namespaceURI === namespace) && this.getLocalTagName(element) === tagName);
    }

    /*
    * @name - getFirstElementByTagNameNS
    * @description - Gets the first matching element that matches the specified tagName and namespace.
    * @static
    * @private
    * @param {Element} context - The context to search within.
    * @param {string} tagName - The tagName to match.
    * @param {string} namespace - The namespace to match.
    * @returns {Element} - The first matching element found, or null if there are no matches.
    */
    private static _getFirstElementByTagNameNS(context: Element, tagName: string, namespace: string): Element {
        let result: Element;
        if (context) {
            const matches = context.getElementsByTagNameNS(namespace, tagName);

            if (matches && matches.length) {
                result = matches[0];
            }
        }

        return result!;
    }
}