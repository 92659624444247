import { IActionContext } from '@msdyn365-commerce/core';
import { addDiscountCodeAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, Coupon } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function addPromoCode(cart: Readonly<Cart | undefined>, promoCode: string, actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED'};
    }

    if (hasPromoCode(cart, promoCode)) {
        return { cart: undefined, status: 'FAILED', substatus: 'ALREADYADDED' };
    }

    return addDiscountCodeAsync({ callerContext: actionContext }, cart.Id, promoCode)
        .then(newCart => {
            // Retail server will still return success if a promo code is not valid,
            // but it won't actually add that promo code. So need to check if promo
            // code is actually in the new cart
            if (hasPromoCode(newCart, promoCode)) {
                return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
            } else {
                return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
            }
        }).catch(error => {
            actionContext.telemetry.warning(error);

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
        });
}

function hasPromoCode(cart: Cart, code: string): boolean {
    const codes = cart.Coupons ? cart.Coupons.map((coupon: Coupon) => { return coupon.Code!.toLowerCase(); }) : [];
    return codes.indexOf(code.toLowerCase()) > -1;
}
